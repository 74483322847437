<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    :additionalInformation="additionalInformation"
    :additionalInformationTooltip="additionalInformationTooltip"
  >
    <b-row >
      <b-col>
        <b-row >
            <b-col cols="12">
              <toggle-button
                class="distanciaVertexBtn"
                v-model="form.fields.incluirDistanciaVertex"
                @change="handleToggle('incluirDistanciaVertex')"
                :sync="true"
                :height="24"
                :disabled="!canEdit"
                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              />
              <span class="autoFillBtnLabel">Distância vértice</span>
            </b-col>
            <b-col cols="12">
              <toggle-button
                class="lentesPrismaticasBtn"
                v-model="form.fields.incluirLentesPrismaticas"
                @change="handleToggle('incluirLentesPrismaticas')"
                :sync="true"
                :height="24"
                :disabled="!canEdit"
                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              />
              <span class="autoFillBtnLabel">Incluir lentes prismáticas</span>
            </b-col>
          <b-col>
            <div class="custom-input-group" >
              <div class="custom-input">
                  <div class="eye-area" >
                    <EyeFill /> D
                  </div>
                  <div class="custom-input eye-box-container">
                    <div
                      class="text-area type-2 with-br"
                      @click="openOptions('esfericoDireito')"
                    >
                      <span >Esférico</span>
                      <div >
                        <input
                          autocomplete="off"
                          type="text"
                          class="form-control text-center"
                          :value="form.fields.olhoDireitoEsferico"
                          :readonly="!canEdit"
                          :disabled="!canEdit"
                          @input="el => debounceInput('olhoDireitoEsferico', el.target.value)"
                        >
                        <MultipleEyesOptions
                          v-if="this.activeEyeBox ==='esfericoDireito' && canEdit"
                          :form="form"
                          activeInput="esfericoDireito"
                          :setActiveInput="this.setActiveEyeBox"
                          @select="({ key, value }) => this.updateForm(key, value)"
                          @blur="updateMedicalRecord(form)"
                          :options="form.fields.olhoDireitoEsferico ?
                            (parseFloat(form.fields.olhoDireitoEsferico.replace(',', '.')) < 0.00 ?
                              optionsNegative : optionsPositive) :
                            optionsPositive"
                        />
                      </div>
                    </div>
                    <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoEsferico', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoEsferico', null)"><p>-</p></button>
                      </div>
                    </div>
                  </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('cilindricoDireito')"
                  >
                    <span>Cilíndrico</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoCilindrico"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoCilindrico', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='cilindricoDireito' && canEdit"
                        :form="form"
                        activeInput="cilindricoDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="form.fields.olhoDireitoCilindrico !== null ?
                          (parseFloat(form.fields.olhoDireitoCilindrico.replace(',', '.')) < 0.00 ?
                            optionsNegative : optionsPositive) :
                          optionsNegative"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoCilindrico', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoCilindrico', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('eixoDireito')"
                  >
                    <span>Eixo</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoEixo"
                        :readonly="!canEdit"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoEixo', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='eixoDireito' && canEdit"
                        :form="form"
                        activeInput="eixoDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsEixo"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 1, 'olhoDireitoEixo', 'eixo');"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 1, 'olhoDireitoEixo', 'eixo')"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('DPDireito')"
                  >
                    <span>D.P.</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoDP"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoDP', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='DPDireito' && canEdit"
                        :form="form" activeInput="DPDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsDP"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.1, 'olhoDireitoDP', 'DP');"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.1, 'olhoDireitoDP', 'DP')"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2"
                    @click="openOptions('AdicaoDireito')"
                  >
                    <span>Adição</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoAdicao"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoAdicao', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='AdicaoDireito' && canEdit"
                        :form="form"
                        activeInput="AdicaoDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsPositive"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoAdicao',  null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoAdicao', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container" v-if="form.fields.incluirDistanciaVertex">
                  <div
                    class="text-area type-2 with-bl"
                    @click="openOptions('DistanciaVertexDireito')"
                  >
                    <span>Distância vértice</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoDistanciaVertex"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoDistanciaVertex', el.target.value)"
                      >
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoDistanciaVertex',  null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoDistanciaVertex', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-input no-bb">
                  <div class="eye-area">
                  <EyeFill /> E
                  </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('esfericoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoEsferico"
                        :readonly="!canEdit"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoEsferico', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox === 'esfericoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="esfericoEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="form.fields.olhoEsquerdoEsferico ?
                          (parseFloat(form.fields.olhoEsquerdoEsferico.replace(',', '.')) < 0.00 ?
                            optionsNegative : optionsPositive) :
                          optionsPositive"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoEsferico', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoEsferico', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('cilindricoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoCilindrico"
                        :readonly="!canEdit"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoCilindrico', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='cilindricoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="cilindricoEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="form.fields.olhoEsquerdoCilindrico !== null ?
                          (parseFloat(form.fields.olhoEsquerdoCilindrico.replace(',', '.')) < 0.00 ?
                            optionsNegative : optionsPositive) :
                            optionsNegative"
                        />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoCilindrico', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoCilindrico', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('eixoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoEixo"
                        :readonly="!canEdit"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoEixo', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='eixoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="eixoEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsEixo"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 1, 'olhoEsquerdoEixo', 'eixo');"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 1, 'olhoEsquerdoEixo', 'eixo')"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('DPEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoDP"
                        :readonly="!canEdit"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoDP', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='DPEsquerdo' && canEdit"
                        :form="form"
                        activeInput="DPEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsDP"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.1, 'olhoEsquerdoDP', 'DP');"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.1, 'olhoEsquerdoDP', 'DP')"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2" @click="openOptions('AdicaoEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoAdicao"
                        :readonly="!canEdit"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoAdicao', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='AdicaoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="AdicaoEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsPositive"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoAdicao', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoAdicao', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container" v-if="form.fields.incluirDistanciaVertex">
                  <div class="text-area type-2 with-bl">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoDistanciaVertex"
                        :readonly="!canEdit"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoDistanciaVertex', el.target.value)"
                      >
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoDistanciaVertex',  null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoDistanciaVertex', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <button
            class="btn blue-underline button-copy"
            @click="copyEyeValues"
            v-if="canEdit"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"
          >
            <v-copy-icon />
          </button>
        </b-row>
        <b-row>
          <b-col class="use-refraction-container">
            <div class="checkbox-wrapper">
              <Check
                :value="form.fields.usarValoresRefracao === 'Est'"
                @input="(checked) => dispatchGetRefractionValues(checked, 'Est')"
                :disabled="!canEdit"
              />
              <div>Usar valores da refração estática</div>
            </div>
            <div class="checkbox-wrapper">
              <Check
                :value="form.fields.usarValoresRefracao === 'Din'"
                @input="(checked) => dispatchGetRefractionValues(checked, 'Din')"
                :disabled="!canEdit"
              />
              <div>Usar valores da refração dinâmica</div>
            </div>
            <div class="checkbox-wrapper">
              <Check
                :value="form.fields.usarValoresRefracao === 'Len'"
                @input="(checked) => dispatchGetRefractionValues(checked, 'Len')"
                :disabled="!canEdit"
              />
              <div>Usar valores da lensometria</div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="form.fields.incluirLentesPrismaticas">
          <b-col cols="6">
            <div class="custom-input-group" >
              <div class="custom-input">
                <div class="eye-area" >
                  <EyeFill /> D
                </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                  >
                    <span>Dioptrias prismáticas</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoDioptriasPrismaticas"
                        :readonly="!canEdit"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoDioptriasPrismaticas', el.target.value)"
                      >
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoDioptriasPrismaticas', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoDioptriasPrismaticas', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2"
                    @click="openOptions('basePrismaticasOlhoDireito')"
                  >
                    <span>Base</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoBasePrismaticas"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoBasePrismatica', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='basePrismaticasOlhoDireito' && canEdit"
                        :form="form"
                        activeInput="basePrismaticasOlhoDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="[['Baixo'], ['Cima'], ['Direita'], ['Esquerda']]"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>

                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area">
                  <EyeFill /> E
                </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                  >
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoDioptriasPrismaticas"
                        :readonly="!canEdit"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoDioptriasPrismaticas', el.target.value)"
                      >
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoDioptriasPrismaticas', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoDioptriasPrismaticas', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2"
                    @click="openOptions('basePrismaticasOlhoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoBasePrismaticas"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoBasePrismaticas', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='basePrismaticasOlhoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="basePrismaticasOlhoEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="[['Baixo'], ['Cima'], ['Direita'], ['Esquerda']]"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label for="materia-prima-lente">Matéria-prima da lente</label>
            <multiselect
              :value="form.fields.materiaPrima"
              id="materia-prima-lente"
              :options="opcoesMateriaPrima"
              :option-height="40"
              :showLabels="false"
              :showNoResults="true"
              :disabled="!canEdit"
              @select="value => onSelect('materiaPrima', value)"
              placeholder="Selecionar"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult" slot-scope="props">
                <li @click="setMateriaPrima(props.search)">
                  <div class="multiselect__option custom-item">
                    <Plus class="icon" />
                    Adicionar "{{ props.search }}"
                  </div>
                </li>
              </template>
            </multiselect>
          </b-col>
          <b-col>
            <label for="tratamento-lente">Tratamento da lente</label>
              <multiselect
                :value="form.fields.tratamentoLente"
                id="tratamento-lente"
                ref="tratamentoLente"
                :options="opcoesTratamentoLente"
                :option-height="40"
                :searchable="false"
                :close-on-select="false"
                :showLabels="false"
                :multiple="true"
                @select="value => onSelect('tratamentoLente', value, true)"
                @remove="value => onRemove('tratamentoLente', value)"
                :disabled="!canEdit"
                placeholder="Selecionar"
                class="with-border multiple"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="selection" slot-scope="{ values }">
                  <div class="treatment">
                    {{ values.join(', ') }}
                  </div>
                </template>
              </multiselect>
          </b-col>
          <b-col>
            <label for="tipo-lente">Tipo de lente</label>
            <multiselect
              :value="form.fields.tipoLente"
              id="tipo-lente"
              :options="opcoesTipoLente"
              :option-height="40"
              :showLabels="false"
              :showNoResults="true"
              @select="value => onSelect('tipoLente', value)"
              :disabled="!canEdit"
              placeholder="Selecionar"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult" slot-scope="props">
                <li @click="setTipoLente(props.search)">
                  <div class="multiselect__option custom-item">
                    <Plus class="icon" />
                    Adicionar "{{ props.search }}"
                  </div>
                </li>
              </template>
            </multiselect>
          </b-col>
          <b-col class="align-self-end">
            <b-button
              :variant="hasProtocol ? 'primary' : 'outline-primary'"
              block
              class="flex"

              @click="$refs['oculosProtocolModal'].show()"
              >
              <span v-if="protocolsSelectedLength > 0">({{protocolsSelectedLength }})</span>
              Protocolo(s)</b-button
            >
          </b-col>
        </b-row>
        <b-row v-if="protocolsSelectedLength > 0">
          <b-col class="mt-3 mx-2">
              <div class="protocol protocols-container" v-for="(protocol, index) in form.fields.protocols" :key="index">
                  <div class="protocol-header col">
                      <div class="protocol-header__informations">
                        <div class="protocol-name">{{ protocol.name }}</div>
                        <div class="separator mx-2">|</div>
                        <div class="protocol-manufacturer">{{ protocol.manufacturer }}</div>
                      </div>

                      <div class="protocol-header__actions">
                        <div class="protocol-remove" @click="removeInstruction(index)">Remover instrução</div>
                      </div>
                  </div>
                  <div class="protocol-content">
                    <div class="protocol-label">
                      <div class="col-4">Lente recomendada</div>
                      <div class="col">Instruções</div>
                    </div>
                    <div class="protocol-recomendations">
                      <div class="protocol-name col-4">{{ protocol.manufacturer }}</div>
                      <ul class="instructions">
                        <li class="instructions" v-for="(instruction, index) in protocol.instructions" :key="index">
                          {{ instruction }}
                        </li>
                      </ul>

                    </div>

                  </div>

              </div>

          </b-col>
        </b-row>
        <b-row>
          <b-col class="guidance-container">
            <label>Instruções</label>
            <v-autocomplete
              v-if="canEdit && instructions?.length"
              class="mb-3"
              @input="value => handleChangeInstruction(value)"
              :options="instructions"
              placeholder="Selecionar instrução"
            />
            <div class="form-group mb-0">
              <VueEditor
                id="lentes-de-contato-orientacao"
                class="vue_edition"
                :value="form.fields.orientacao"
                :editorToolbar="customToolbar"
                @input="value => debounceInput('orientacao', value)"
                :disabled="!this.validCanEdit()"
              />
            </div>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col>
            <div class="brand checkbox-wrapper">
              <Check
                :value="form.fields.marcaRecomendada === 'ZEISS'"
                @input="setMarcaRecomendada"
                :disabled="!canEdit"
              />
              <div>Recomendar lentes ZEISS</div>
            </div>
          </b-col>
        </b-row> -->
      </b-col>
    </b-row>
    <b-row class="px-2">
      <div class="d-flex justify-content-center extra-glasses-wrapper">
         <!--<b-button variant="outline" class="extra-glasses-button" @click="openExtraGlasses">
          Receitar óculos extra
        </b-button>-->
      </div>
    </b-row>
    <ProtocolsModal
      ref="oculosProtocolModal"
      @onChange="value => saveProtocolsData(value)"
      :protocols="form.fields.protocols || []"
      :insertPatientDataProps="!!form.fields.patientData"
      :patientBirthday="attendance?.attendance?.patient?.birthday"
      :refractions="refractions"
    />
  </FormWrapper>
</template>

<script>
import { debounce } from 'lodash';
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import MultipleEyesOptions from '@/components/Attendance/Forms/Components/MultipleEyesOptions'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Check from '@/components/General/Check'
import { VueEditor } from "vue2-editor"
import Copy from '@/assets/icons/copy.svg'
import Autocomplete from '@/components/Autocomplete'
import ProtocolsModal from '@/components/Attendance/Forms/Components/ProtocolsModal'
import oculosProtocolsMixin from '@/mixins/oculosProtocols/oculosProtocolsMixin'
import { gotToOption } from '@/utils/attendanceHelper'

export default {
  mixins: [oculosProtocolsMixin],

  components: {
    FormWrapper,
    MultipleEyesOptions,
    ChevronDown,
    Plus,
    EyeFill,
    Check,
    VueEditor,
    'v-copy-icon' : Copy,
    'v-autocomplete': Autocomplete,
    ProtocolsModal
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      canDefaultForms: state => state.attendance.canDefaultForms,
      form: state => state.attendance.form.oculos,
      attendance: state => state.attendance,
    }),
    protocolsSelectedLength() {
      return this.form?.fields?.protocols?.length
    },
    additionalInformation() {
      return this.hasProtocol ? 'Recomendar produto' : null
    },
    additionalInformationTooltip() {
      return this.hasProtocol ? 'Paciente é elegível para o(s) protocolo(s) com base nos resultados do exame de refração. Consulte o "Protocolo" sob os exames na "Refração" ou "Óculos" na seção "Conduta" e escolha a(s) opção(ões) mais adequada(s).' : null
    },
    refractions() {
      return {
        olhoEsquerdoCilindrico: this.form.fields.olhoEsquerdoCilindrico,
        olhoDireitoCilindrico: this.form.fields.olhoDireitoCilindrico,
        olhoEsquerdoEsferico: this.form.fields.olhoEsquerdoEsferico,
        olhoDireitoEsferico: this.form.fields.olhoDireitoEsferico
      }
    },
    hasProtocol() {
      return this.verifyProtocol(this.refractions, this.attendance?.attendance?.patient?.birthday)
    }
  },
  created() {
    this.getStandards()
  },
  data() {
    return {
      activeEyeBox: String,
      opcoesTratamentoLente: ['Antirreflexo', 'Antirrisco', 'Filtro de luz azul', 'Fotossensível', 'Proteção UV', 'Antiembaçante', 'Antiengordurante', 'Antivírus'],
      opcoesTipoLente: ['Para Longe', 'Para Perto', 'Multifocal', 'Multifocal Intermediário/Perto','Bifocal', 'Longe e perto separados'],
      opcoesMateriaPrima: ['Resina', 'Policarbonato', 'Alto Índice', 'Trivex'],
      optionsPositive: [['+0,25', '+0,50', '+0,75', '+1,00', '+1,25'], ['+1,50', '+1,75', '+2,00', '+2,25', '+2,50'], ['+2,75', '+3,00', '+3,25', '+3,50', '+3,75'], ['+4,00', '+4,25', '+4,50', '+4,75', '+5,00']],
      optionsNegative: [['-0,25', '-0,50', '-0,75', '-1,00', '-1,25'], ['-1,50', '-1,75', '-2,00', '-2,25', '-2,50'], ['-2,75', '-3,00', '-3,25', '-3,50', '-3,75'], ['-4,00', '-4,25', '-4,50', '-4,75', '-5,00']],
      optionsEixo: [['5º', '10º', '15º', '20º', '25º', '30º'], ['35º', '40º', '45º', '50º', '55º', '60º'], ['65º', '70º', '75º', '80º', '85º', '90º'], ['95º', '100º', '105º', '110º', '115º', '120º'], ['125º', '130º', '135º', '140º', '145º', '150º'], ['155º', '160º', '165º', '170º', '175º', '180º']],
      optionsDP: [['3,0', '3,5', '4,0', '4,5'], ['5,0', '5,5', '6,0', '6,5'], ['7,0', '7,5', '8,0', '8,5'], ['9,0', '9,5', '10,0', '10,5']],
      customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
      standard: null,
      instructions: [],
      user: JSON.parse(localStorage.getItem('user')),
    }
  },
  mounted() {
    this.debounceInput = debounce(this.onInput, 300)
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord', 'handleActiveModule']),
    ...mapActions('attendance/form/oculos', ['handleFields', 'handleProps']),
    ...mapActions('attendance/form/refracaoEstatica', { useEstaticValuesOnGlasses: 'useValuesOnGlasses' }),
    ...mapActions('attendance/form/refracaoDinamica', { useDinamicValuesOnGlasses: 'useValuesOnGlasses' }),
    ...mapActions('attendance/form/lensometria', { useLensometricValuesOnGlasses: 'useValuesOnGlasses' }),
    ...mapActions('attendance/form/oculosExtra', { handleExtraGlasses: 'handleProps' }),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    async openExtraGlasses(ev){
      await this.handleExtraGlasses({key: 'showing', value: true})
      await this.handleExtraGlasses({key: 'opened', value: true})
      ev.stopPropagation()
      this.handleActiveModule('oculos-extra')
      gotToOption('oculos-extra')
    },
    onInput(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    openOptions(input){
      this.activeEyeBox === input ? this.activeEyeBox = null : this.activeEyeBox = input
    },
    handleToggle(key){
      this.updateForm(key, this.form.fields[key])
      this.updateMedicalRecord(this.form)
    },
    setActiveEyeBox(BoxName){
      this.activeEyeBox = BoxName
    },
    copyEyeValues(){
      this.updateForm('olhoEsquerdoEsferico', this.form.fields.olhoDireitoEsferico)
      this.updateForm('olhoEsquerdoCilindrico', this.form.fields.olhoDireitoCilindrico)
      this.updateForm('olhoEsquerdoEixo', this.form.fields.olhoDireitoEixo)
      this.updateForm('olhoEsquerdoAdicao', this.form.fields.olhoDireitoAdicao)
      this.updateForm('olhoEsquerdoDP', this.form.fields.olhoDireitoDP)
      this.updateForm('olhoEsquerdoDistanciaVertex', this.form.fields.olhoDireitoDistanciaVertex)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    onSelect(key, value, multi) {
      if (multi) {
        const option = value
        value = this.form.fields[key] || []
        value.push(option)
      }
      this.updateForm(key, value)
      this.updateMedicalRecord(this.form)
    },
    onRemove(key, option) {
      const value = this.form.fields[key]
      const index = value.indexOf(option)
      value.splice(index, 1)
      this.updateForm(key, value)
      this.updateMedicalRecord(this.form)
    },
    dispatchGetRefractionValues(checked, suffix) {
      switch (suffix) {
        case 'Est':
          this.useEstaticValuesOnGlasses(checked)
          break;
        case 'Din':
          this.useDinamicValuesOnGlasses(checked)
          break;
        case 'Len':
            this.useLensometricValuesOnGlasses(checked)
            break;
        default:
          break;
      }
    },
    inputTextArea(value) {
      this.updateForm('orientacao', value)
      this.updateMedicalRecord(this.form)
    },
    setMarcaRecomendada(checked) {
      this.updateForm('marcaRecomendada', checked ? 'ZEISS' : null)
      this.updateMedicalRecord(this.form)
    },
    setTipoLente(value) {
      if (!value.length) return;
      this.opcoesTipoLente.push(value);
      this.updateForm('tipoLente', value)
      this.updateMedicalRecord(this.form)
    },
    setMateriaPrima(value) {
      if (!value.length) return;
      this.opcoesMateriaPrima.push(value);
      this.updateForm('materiaPrima', value)
      this.updateMedicalRecord(this.form)
    },
    changeInputValue(change, amount, key, type){
      if(this.form.fields[key] === '' || this.form.fields[key] === null){
        this.form.fields[key] = '0'
      }
      if(!(isNaN(parseFloat(this.form.fields[key])))){
          if(type === 'eixo'){
              if(change === 'increase'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(0)).replace('.', ','))

              } else if(change === 'decrease'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(0)).replace('.', ','))
              }
              if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
                this.updateForm(key, this.form.fields[key]+'º')
              }
          }

          else if(type === 'DP'){
              if(change === 'increase'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(1)).replace('.', ','))

              } else if(change === 'decrease'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(1)).replace('.', ','))
              }
              if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
                this.updateForm(key, this.form.fields[key]+'')
              }
            }

          else if(!(isNaN(parseFloat(this.form.fields[key])))){
            if(change === 'increase'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(2)).replace('.', ','))

            } else if(change === 'decrease'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(2)).replace('.', ','))
            }
            if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
              this.updateForm(key, '+'+this.form.fields[key])
            }
        }
        this.updateMedicalRecord(this.form)
      }
    },
    async getStandards() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getStandards(this.user.id)
        this.standard = response.data.find(std => std.type === "oculos")
        this.instructions =
          this.standard?.value?.instructions
          && this.standard?.value.instructions.map(el => {
            return {
              label: el.title,
              value: el.content
            }
          })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    handleChangeInstruction (value) {
      this.updateForm('orientacao', value)
      this.updateMedicalRecord(this.form)
    },
    handleSomaAutomatica () {
      if (
        this.form?.fields?.somaAutomatica === null
        && this.standard?.active
        && this.canEdit
      ) {
        this.updateForm('somaAutomatica', this.standard?.value?.autoSum)
        this.updateMedicalRecord(this.form)
      }
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    removeInstruction(index) {
      this.form.fields.protocols.splice(index, 1)

      if (this.protocolsSelectedLength === 0) {
        this.saveProtocolsData({ protocols: [], insertPatientData: false })
      } else {
      this.updateMedicalRecord(this.form)

      }
    },
    saveProtocolsData(value) {
      this.updateForm('protocols', value.protocols)
      if (value.insertPatientData) {
        const patient = this.attendance?.attendance?.patient

        if (patient) {
          this.updateForm('patientData', {
            cpf: patient.cpf,
            birthday: patient.birthday,
            address: patient.endereco
          })
        }
      } else {
        this.updateForm('patientData', null)
      }
      this.updateMedicalRecord(this.form)
    },
    handleProtocols(refractions, patientBirthday) {
      const hasProtocol = this.verifyProtocol(refractions, patientBirthday)

      if (hasProtocol) {
        return true
      } else {
        this.saveProtocolsData({ protocols: [], insertPatientData: false })
        return false
      }
    },
    validCanEdit() {
      if(this.canDefaultForms === true || this.canEdit === true){
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    form() {
      if (!this.form) return
      const opcoesTipoLente = [...this.opcoesTipoLente]
      if (this.form.fields.tipoLente && !opcoesTipoLente.includes(this.form.fields.tipoLente)) {
        opcoesTipoLente.push(this.form.fields.tipoLente);
      }
      this.opcoesTipoLente = opcoesTipoLente

      const opcoesMateriaPrima = [...this.opcoesMateriaPrima]
      if (this.form.fields.materiaPrima && !opcoesMateriaPrima.includes(this.form.fields.materiaPrima)) {
        opcoesMateriaPrima.push(this.form.fields.materiaPrima);
      }
      this.opcoesMateriaPrima = opcoesMateriaPrima
    },
    'form.fields.olhoDireitoAdicao': function (value) {
      if(value && !this.form?.fields?.somaAutomatica)
        this.handleSomaAutomatica()
    },
    'form.fields.olhoEsquerdoAdicao': function (value) {
      if(value && !this.form?.fields?.somaAutomatica)
        this.handleSomaAutomatica()
    },
  }
}
</script>
<style lang="scss" scoped>
  .custom-input-group {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--neutral-300);
    border-radius: 8px;
    margin: 24px 0 16px 0;

    .custom-input {
      flex: 1;
      height: 38px;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      justify-content: center;

      .eye-area {
        width: 60px;
        background-color: var(--neutral-100);
        border-right: 1px solid var(--neutral-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 0 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--type-active);
          margin-right: 4px;
        }
      }

      &.no-bb {
        border-bottom: 0;

        .eye-area {
          border-radius: 0 0 0 8px;
        }
      }

      .text-area {
        flex: 1;
        line-height: 55px;
        margin-left: 16px;
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);

        .form-control {
          border: 0 !important;
          border-radius: 0 !important;

          &.with-bbr {
            border-radius: 0 0 8px 0 !important;
          }

          &.with-btr {
            border-radius: 0 8px 0 0 !important;
          }
        }

        &.type-2 {
          position: relative;
          margin-left: 0;
          text-align: center;

          span {
            width: 100%;
            position: absolute;
            top: -19px;
            left: 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: var(--type-active);
          }
        }
        &.with-br {
          border-right: 1px solid var(--neutral-300) !important;
        }
        &.with-bl {
          border-left: 1px solid var(--neutral-300) !important;
        }

        &.with-brtr {
          border-radius: 0 8px 0 0 !important;
        }

        &.with-brbr {
          border-radius: 0 0 8px 0 !important;
        }
      }
    }
    }
  label {
    margin-top: 16px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
    color: var(--dark-blue);
  }

  .eyeValueChange {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 !important;
    position: relative;
    margin-left: -30px !important;
    z-index: 5;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 30px;

      button {
        height: 20px;
        width: 30px;
        color: var(--greys-60);
        background-color: #fff;
        position: relative !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1.5px solid var(--blue-100) !important;

        p {
          font-weight: 700;
        }

      }
    }
  }

  .eye-box-container {
    .eyeValueChange {
      visibility: hidden;
    }

    &:hover {
      .eyeValueChange {
        visibility: visible;
      }
    }
  }

  .use-refraction-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .guidance-container {
    margin-top: 8px;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: var(--blue-500)
  }

  .treatment {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

     @media (min-width: 900px) {
      max-width: 10ch;
    }

    @media (min-width: 1200px) {
      max-width: 15ch;
    }
    @media (min-width: 1400px) {
      max-width: 20ch;
    }
    @media (min-width: 1600px) {
      max-width: 25ch;
    }
  }

  .brand {
    margin-top: 20px;
  }
  .vue_edition {
    background-color: white;

    :deep(.ql-editor) {
      min-height: 100px !important;
    }
  }

  .protocols-container {
    border: 1px dashed #305BF2;
    border-radius: 8px;

    padding: 4px 2px;

    display: flex;
    flex-direction: column;

    margin-bottom: 5px;

      .protocol-header {
        display:flex;
        flex-direction: row;
        justify-content: space-between;

        font-size: 16px;
        color: var(--dark-blue);

        margin-top: 2px;
        margin-bottom: 10px;

        .protocol-header__informations {
          display: flex;
          flex-direction: row;

          font-weight: 700;
          font-weight: 14px;
        }

        .protocol-header__actions .protocol-remove {
          color: red;
          cursor: pointer;
        }
      }

      .protocol-content {
        display: flex;
        flex-direction: column;

        .protocol-label {
          display: flex;
          flex-direction: row;

          font-size: 14px;
          font-weight: 600;
          border-bottom: 1px solid #C6CEEB;
        }

        .protocol-recomendations {
          display: flex;
          flex-direction: row;

          font-weight: 400;
          font-size: 16px;

          .instructions {
            flex-direction: column;
          }
        }
      }
  }
  .extra-glasses-wrapper {
    margin-top: 16px;
    border-top: 1px solid var(--neutral-300);
    width: 100%;

    .extra-glasses-button {
      margin-top: 10px;
      margin-bottom: 5px;
      background-color: var(--blue-100);
      color: var(--blue-500);
      border-radius: 8px;
    }
  }
</style>
